.app
{
    min-height: 100%;
    min-height: 100vh;
}


.energySaving
{
    transition-duration: 1s;
    filter: brightness(20%);
}
